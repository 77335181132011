import { useUserContext } from "../../contexts/user";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Auth } from "@supabase/auth-ui-react";
import { supabase } from "../../lib/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const { signedIn } = useUserContext();
  useEffect(() => {
    if (signedIn) {
      navigate("/");
    }
  });
  return (
    <div className="mx-auto max-w-2xl px-2 sm:px-6 lg:px-8">
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={["google", "facebook"]}
      />
    </div>
  );
};
