import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGlobalAudioPlayer } from "react-use-audio-player";
import ReactGA from "react-ga4";
import type { Get } from "type-fest";
import { DbType } from "../lib/const";
import { supabase } from "../lib/client";
import { useUserContext } from "./user";
// @ts-ignore
import useGeoLocation from "react-ipgeolocation";
import { cdnUrl } from "../lib/util";

interface ISound {
  url: string;
  id?: number;
}
export interface IPlayerContext {
  play: (url: string, id?: number) => void;
  stop: () => void;
  sound: ISound | undefined;
}

export type InsertRow = Get<DbType, "public.Tables.listen_count.Insert">;

const Context = createContext<IPlayerContext>({} as IPlayerContext);

const registerListen = ({ s_id, u_id }: InsertRow) => {
  return supabase.from("listen_count").insert({
    s_id,
    u_id,
  });
};



export const PlayerContext = ({ children }: { children: ReactNode }) => {
  const { load, stop } = useGlobalAudioPlayer();
  const { user } = useUserContext();
  const location = useGeoLocation();
  const [sound, setSound] = useState<ISound | undefined>(undefined);

  useEffect(() => {
    if (sound?.url) {
      load(sound?.url, {
        autoplay: true,
        html5: true,
        format: "mp3",
      });

      return () => {
        stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound, load]);

  const play = async (path: string, id?: number) => {
    setSound({ url: `${cdnUrl(location.country)}/${path}` });
    if (id) {
      ReactGA.event("sound_play", {
        id,
      });
      await registerListen({
        s_id: id,
        u_id: user?.id,
      });
    }
  };
  return (
    <Context.Provider value={{ play, stop, sound }}>
      {children}
    </Context.Provider>
  );
};
export const usePlayerContext = () => useContext(Context);
