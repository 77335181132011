import { useEffect, useState } from "react";
import { useUserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/client";
import { useRealtimeContext } from "../../contexts/realtime";
import { v4 as uuidv4 } from "uuid";
import { WaveSurfer } from "../../components/Wavesurfer";
import Timeline from "wavesurfer.js/dist/plugins/timeline";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import { base64ToBufferAsync } from "../../lib/util";
import { ProgressBar } from "../../components/ProgressBar";
import classNames from "classnames";
import slugify from "slugify";
import { CategoryRow } from "../Root";
import { generate as wordGenerate } from "random-words";

export const Upload = () => {
  const { signedIn, validated, user } = useUserContext();
  const { subscription, setChannelId, onMessage, channelId } =
    useRealtimeContext();
  const [name, setName] = useState<string>("");
  const [color, setColor] = useState<string>("red");
  const [url, setUrl] = useState<string>("");
  const [audioFile, setAudioFile] = useState<Blob>();
  const [isFetching, setIsFetching] = useState(false);
  const [percent, setPercent] = useState(0);
  const [categories, setCategories] = useState<Array<CategoryRow> | null>();
  const [categoryId, setCategoryId] = useState<number | undefined>();
  const [progressMessage, setProgressMessage] = useState("");
  const [progressError, setProgressError] = useState(false);

  const fetchClasses = classNames(
    "rounded-md",
    "bg-blue-600",
    "disabled:bg-blue-200",
    "px-3",
    "py-1.5",
    "text-sm",
    "font-semibold",
    "leading-6",
    "text-white",
    "shadow-sm",
    "hover:bg-blue-500",
    "focus-visible:outline",
    "focus-visible:outline-2",
    "focus-visible:outline-offset-2",
    "focus-visible:outline-blue-600",
    { hidden: !!audioFile }
  );

  // const audioRef = useRef<HTMLAudioElement>()
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (subscription) {
      // console.log(subscription);
    }
  }, [subscription]);

  useEffect(() => {
    if (!signedIn && validated) {
      navigate("/login");
    }
    if (signedIn && validated) {
      setChannelId(`${user?.id}/${uuidv4()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, validated]);

  const fetchCategories = async () => {
    const { data } = await supabase
      .from("categories")
      .select("*")
      .order("position", { ascending: true });
    setCategories(data);
    if (data) {
      setCategoryId(data[0].id)
    }
    
  };

  const invokeFetch = async () => {
    if (url && !isFetching) {
      setIsFetching(true);
      setProgressError(false);
      setPercent(0);
      setProgressMessage("Start uploading");
      setAudioFile(undefined);

      const formData = new FormData();

      // formData.append("color", color);
      // formData.append("name", name);
      formData.append("chatId", channelId);
      formData.append("url", url);

      const { data, error } = await supabase.functions.invoke("fetch", {
        body: formData,
      });
      if (error) {
        setProgressError(true);
        setPercent(100);
        setProgressMessage("Error during upload");
      }
      if (data) {
        setProgressError(false);
        setPercent(10);
        setProgressMessage("Delivered fetch request");
      }
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAudioFile(e.target.files[0]);
      setProgressError(false);
      setPercent(100);
      setProgressMessage("Loaded file");
    }
  };

  const upload = async (sound: Blob) => {
    if (!user) {
      setProgressError(true);
      setPercent(100);
      setProgressMessage("Not logged in");
      return;
    }

    if (!name || !color || !categoryId) {
      setProgressError(true);
      setPercent(0);
      setProgressMessage("Please check if sound name, color and category are defined");
      return;
    }
    setProgressError(false);
    setPercent(0);
    setProgressMessage("Uploading sound");
    const { data, error } = await supabase.storage
      .from("media-4j-ee")
      .upload(`${user?.id}/${crypto.randomUUID()}.mp3`, sound, {
        contentType: "audio/mpeg",
        cacheControl: "3600",
        upsert: false,
      });

    if (error) {
      setProgressError(true);
      setPercent(100);
      setProgressMessage(error.message);
    }
    if (data && user) {
      setProgressError(false);
      setPercent(50);
      setProgressMessage("Saving sound");
      let slug = slugify(name, {
        replacement: "-",
        lower: true,
        strict: true,
      });
      const {data: existing, error: existingError} = await supabase.from("sounds").select("id").eq("slug", slug).single();
      if (existing && !existingError) {
        slug = `${slug}-${wordGenerate()}`
      }
      const { error: insertError } = await supabase.from("sounds").insert({
        sound_path: data.path,
        category_id: categoryId,
        title: name,
        color,
        slug,
      });

      if (insertError) {
        setProgressError(true);
        setPercent(100);
        setProgressMessage(insertError.message);
      } else {
        navigate(`/sound/${slug}`);
      }
    }
  };

  onMessage(async ({ payload }) => {
    switch (payload.stage) {
      case "ytdl-metadata":
        switch (payload.status) {
          case "success":
            setPercent(20);
            setProgressMessage("Fetched metadata");
            break;
          case "error":
            setPercent(100);
            setProgressError(true);
            setProgressMessage("Error");
            break;
        }
        break;

      case "ytdl-fetch":
        switch (payload.status) {
          case "in_process":
            switch (payload.response.stage) {
              case "init":
                setPercent(30);
                setProgressMessage("Finding appropiate format");
                break;
              case "start":
                setPercent(40);
                setProgressMessage("Started fetching");
                break;
            }
            break;
          case "success":
            setPercent(80);
            setProgressMessage("Fetched media");
            break;
          case "error":
            setPercent(100);
            setProgressError(true);
            setProgressMessage("Error");
            break;
        }
        break;
      case "root-job":
        switch (payload.status) {
          case "success":
            setPercent(100);
            setProgressMessage("Sending media to browser");
            break;
          case "error":
            setPercent(100);
            setProgressError(true);
            setProgressMessage("Error");
            break;
        }
        break;
    }
    if (payload.response?.file) {
      const buffer = await base64ToBufferAsync(payload.response?.file);
      const blob = new Blob([buffer], { type: "audio/mp3" });
      setAudioFile(blob);
    }
  });

  return (
    <div className="flex flex-col flex-wrap w-full justify-center sm:px-4 pt-6">
      <div className="mb-3 w-full">
        <label
          htmlFor="formFile"
          className="mb-4 inline-block text-neutral-700 dark:text-neutral-200"
        >
          Choose the mp3 file. <br />
          <span className="font-normal text-sm text-gray-500">
            Maxiumum duration 2 minutes and maxiumum size 3Mb
          </span>
        </label>
        <input
          className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
          type="file"
          id="formFile"
          onChange={handleFileChange}
        />
      </div>
      <div className="mb-3 w-full">
        <label
          htmlFor="url"
          className="mb-4 inline-block text-neutral-700 dark:text-neutral-200"
        >
          Or pase the link to youtube, reels or tiktok
        </label>
        <input
          className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
          type="text"
          id="url"
          value={url}
          onChange={(evt) => setUrl(evt.target.value)}
        />
      </div>
      <div className="mb-3 w-full flex flex-row flex-wrap">
        <div className="w-full sm:w-1/3 pr-0 sm:pr-2 mb-2 sm:mb-0">
          <input
            type="text"
            className="block w-full h-10 rounded-md border-0 p-1.5 sm:p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            placeholder="Sound name"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </div>
        <div className="w-full sm:w-1/3 pr-0 sm:pr-2 mb-2 sm:mb-0">
          <select
            name="color"
            className="block w-full h-10 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            value={color}
            onChange={(evt) => setColor(evt.target.value)}
          >
            <option value={"red"} defaultChecked={true}>
              Red
            </option>
            <option value={"blue"}>Blue</option>
            <option value={"brown"}>Brown</option>
            <option value={"green"}>Green</option>
            <option value={"grey"}>Grey</option>
            <option value={"light-brown"}>Light Brown</option>
            <option value={"orange"}>Orange</option>
            <option value={"pink"}>Pink</option>

            <option value={"teal"}>Teal</option>
            <option value={"violet"}>Violet</option>
            <option value={"white"}>White</option>
            <option value={"yellow"}>Yellow</option>
          </select>
        </div>
        <div className="w-full sm:w-1/3">
          <select
            name="color"
            className="block w-full h-10 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            value={categoryId}
            onChange={(evt) => setCategoryId(+evt.target.value)}
          >
            {categories &&
              categories.map((c, i) => (
                <option value={c.id} defaultChecked={i === 0} key={`c-u-${c.id}`}>
                  {c.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        className="my-2"
        // style={{ display: !!audioFile ? "none" : "block" }}
      >
        {(audioFile || url) && (
          <ProgressBar
            percent={percent}
            message={progressMessage}
            isError={progressError}
          />
        )}
      </div>
      {url && (
        <button
          type="button"
          className={fetchClasses}
          disabled={isFetching}
          onClick={() => invokeFetch()}
        >
          FETCH AUDIO
        </button>
      )}

      {audioFile && (
        <WaveSurfer
          onReady={async (data) => {
            await upload(data);
          }}
          height={100}
          waveColor="rgb(200, 0, 200)"
          progressColor="rgb(100, 0, 100)"
          // media={audioRef.current}
          blob={audioFile}
          sampleRate={44100}
          plugins={[Timeline.create(), RegionsPlugin.create()]}
        />
      )}
    </div>
  );
};
