import { Range, getTrackBackground } from "react-range";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

const STEP = 0.1;

type RangePropes = {
  min: number;
  max: number;
  v0?: number;
  v1?: number;
  setRange?: ( args: number[]) => void
};

// Copy of TwoThumbs with `draggableTrack` prop added
export const TwoThumbsDraggableTrack: React.FC<RangePropes> = ({
  min,
  max,
  v0,
  v1,
  setRange
}) => {
  const [values, setValues] = useState([0, 0]);
  
  useEffect( () => {
    if (max) {
      setValues([0, max])
    }
  }, [max])

  useEffect( () => {
    if (v0 !== undefined && v1 !== undefined) {
      setValues([v0, v1])
    }
  }, [v0, v1])

  return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Range
          draggableTrack
          values={values}
          step={STEP}
          min={min}
          max={max}
          rtl={false}
          onChange={(values) => {
            setValues(values);
            if (setRange) {
              setRange(values)
            }
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="w-full h-8 flex flex-row flx-nowrap"
            >
              <div
                ref={props.ref}
                className="h-2 w-full rounded self-center"
                style={{
                  background: getTrackBackground({
                    values,
                    colors: [
                      "rgb(229,231,235)",
                      "rgb(59,130,246)",
                      "rgb(229,231,235)",
                    ],
                    min: min,
                    max: max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              className="h-10 w-10 rounded bg-white flex justify-center self-center border border-gray-500"
            >
              <div className="h-4 w-4 my-auto">
                {index > 0 ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </div>
              {/* <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            /> */}
            </div>
          )}
        />
        {/* <output style={{ marginTop: "30px" }} id="output">
          {values[0].toFixed(1)} - {values[1].toFixed(1)}
        </output> */}
      </div>
    )
};
