import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Mobile } from "../Categories/Mobile";
import { useState } from "react";
import { CategoryRow } from "../../routes/Root";

export type MobileMenuProps = {
  hidden: boolean;
  closeEmitter: () => void;
  categories: CategoryRow[] | null | undefined;
};

export const MobileMenu = ({ hidden, closeEmitter, categories }: MobileMenuProps) => {
  const [showCategories, setShowCategories] = useState(false);
  const classes = classNames("space-y-1", "px-2", "pb-3", "pt-2", {
    hidden,
  });

  return (
    <div className={classes}>
      <NavLink
        to="/upload"
        role="navitem"
        aria-current="page"
        className="bg-blue-500 text-white block rounded-md px-3 py-3 text-base font-medium"
        onClick={() => closeEmitter()}
      >
        UPLOAD
      </NavLink>
      <NavLink
        to="/"
        className="bg-gray-900 text-white block rounded-md px-3 py-3 text-base font-medium"
        aria-current="page"
        role="navitem"
        onClick={() => closeEmitter()}
      >
        HOME
      </NavLink>
      <NavLink
        to="/new"
        className="bg-gray-900 text-white block rounded-md px-3 py-3 text-base font-medium"
        aria-current="page"
        role="navitem"
        onClick={() => closeEmitter()}
      >
        NEW
      </NavLink>
      <NavLink
        to="/trending"
        className="bg-gray-900 text-white block rounded-md px-3 py-3 text-base font-medium"
        aria-current="page"
        role="navitem"
        onClick={() => closeEmitter()}
      >
        TRENDING
      </NavLink>
      <button
        type="button"
        role="menu"
        className="bg-gray-900 text-white rounded-md px-3 py-3 text-base font-medium w-full inline-flex flex-row justify-start"
        onClick={() => setShowCategories(!showCategories)}
      >
        <span>CATEGORIES</span>
        <ChevronDownIcon className="h-6 w-auto text-gray-300" />
      </button>
      <Mobile categories={categories} showCategories={showCategories} setShowCategories={ (val: boolean) => {
        setShowCategories(val)
        closeEmitter()
      }} />
    </div>
  );
};
