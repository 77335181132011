import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Subscription, User } from "@supabase/supabase-js";
import { supabase } from "../lib/client";

export interface IUserContext {
  logout: () => Promise<void>;
  user: User | undefined;
  signedIn: boolean;
  validated: boolean;
}

const Context = createContext<IUserContext>({} as IUserContext);

export const UserContext = ( {children } : {children: ReactNode}) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    let subscription: Subscription | undefined = undefined;
    const fetchSession = async () => {
      const { data: sessionData } = await supabase.auth.getSession();
      setUser(sessionData.session?.user);

      const { data } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          const currentUser = session?.user;
          setUser(currentUser);
          setValidated(true)
        }
      );
      subscription = data.subscription;
    };
    fetchSession();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <Context.Provider value={{ logout, user, signedIn: !!user, validated }}>
      {children}
    </Context.Provider>
  );
}

export const useUserContext = () => useContext(Context);