import classNames from "classnames";
export interface IProgressBarProps {
  percent: number;
  message: string;
  isError?: boolean;
}
export const ProgressBar = ({
  percent = 0,
  message = "",
  isError = false
}: IProgressBarProps) => {
  const barClass = classNames("h-3", "rounded-md", {
    "bg-blue-600": !isError,
    "bg-red-600": isError
  })
  const messageClass = classNames("text-sm", "font-medium", "dark:text-white", {
    "text-blue-700": !isError,
    "text-red-700": isError
  })
  return (
    <>
      <div className="flex justify-between mb-1">
        <span className={messageClass}>
          {message}
        </span>
        <span className="text-sm font-medium text-blue-700 dark:text-white">
          {percent}%
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-md h-3 dark:bg-gray-700">
        <div className={barClass} style={{width: percent +"%"}}></div>
      </div>
    </>
  );
};
