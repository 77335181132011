// Import WaveSurfer
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";
import { MutableRefObject, useEffect, useState } from "react";
import { useMp3Worker } from "./mp3Worker/useMp3Worker";

// WaveSurfer hook
export const useWavesurfer = (
  containerRef: MutableRefObject<HTMLElement | null>,
  options: Partial<WaveSurferOptions>
) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const worker = useMp3Worker();

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef?.current) return;

    const ws = WaveSurfer.create({
      ...options,
      container: containerRef.current,
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [options, containerRef]);

  type DownloadOpts = {
    start?: number;
    end?: number;
    kbps?: number;
  };

  const download = ({ start = 0, end = 20, kbps = 256 }: DownloadOpts): Promise<Blob> => {
    return new Promise<Blob>( (res, rej) => {
      if (wavesurfer) {
        const MAX_AMPLITUDE = 0x7fff;
        const decodedData = wavesurfer.getDecodedData();
        if (decodedData) {
          const channels = decodedData.numberOfChannels;
          const sampleRate = decodedData.sampleRate;
          const samples: Int16Array[] = [...Array(channels)].map((channelPos) => {
            const samples_float32 = decodedData
              .getChannelData(channelPos)
              .slice(start * sampleRate, end * sampleRate);
            const adjusted = samples_float32.map((x) => {
              let adj = Math.min(1, Math.max(-1, x));
              return Math.round(adj * MAX_AMPLITUDE);
            });
            return new Int16Array(adjusted);
          });
          // Attach an event listener to receive calculations from your worker
          worker.onmessage = (message: { data: { result: Blob }}) => {
            res(message.data.result);
          };
          worker.generateMp3({
            kbps,
            channels,
            sampleRate,
            samples,
          });
        }
      }
    })
  };

  return { wavesurfer, download };
};
