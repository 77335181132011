import { NavLink } from "react-router-dom";
import { DesktopMenu } from "./DektopMenu";
import { MobileMenu } from "./MobileMenu";
import { MobileMenuButton } from "./MobileMenuButton";
import { ProfileMenu } from "./ProfileMenu";
import { useState } from "react";
import { CategoryRow } from "../../routes/Root";

interface INavbarProps {
  showCategories: boolean;
  setShowCategories: (flag: boolean) => void
  categories: CategoryRow[] | null | undefined;
}

export const Navbar = ({showCategories, setShowCategories, categories}: INavbarProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <nav className="bg-black">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <MobileMenuButton setShowMenuHandler={(val) => setShowMobileMenu(val)} showMenuFlag={showMobileMenu} />
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch">
            <div className="flex flex-shrink-0 items-center">
              <NavLink to="/">
                <img className="h-8 w-auto" src="/logo.png" alt="Home" />
              </NavLink>
              
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <DesktopMenu showCategories={showCategories} setShowCategories={setShowCategories} />
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <ProfileMenu />
          </div>
          
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      <div className="sm:hidden" id="mobile-menu">
        <MobileMenu hidden={!showMobileMenu} closeEmitter={ () => setShowMobileMenu(false)} categories={categories}/>
      </div>
    </nav>
  );
};
