import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { supabase } from "../../lib/client";

import { Button, ButtonProps } from "../../components/Button";
import { getSoundBySlug } from "../../lib/client";
import { fromDb } from "../../components/Button/index";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Helmet from "react-helmet";
import { cdnUrl, renderLocationImage } from "../../lib/util";
import { FavoriteFillSVG } from "../../components/Button/buttons";
import { saveAs } from "file-saver";
import { useUserContext } from "../../contexts/user";

export const Sound = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [sound, setSound] = useState<ButtonProps>();
  const [isFav, setIsFav] = useState<number | null>();
  const { signedIn } = useUserContext();

  useEffect(() => {
    if (slug) {
      getSoundBySlug(slug).then((resp) => {
        if (resp && resp.data && !resp.error) {
          const s = fromDb(resp.data);
          setSound(s);
          setIsFav(s.favId);
        } else {
          navigate("/");
        }
      });
    }
  }, [slug]);

  useEffect(() => {
    if (sound) {
      setSound((prev) => {
        if (prev) {
          return {
            ...prev,
            favId: isFav,
          };
        }
      });
    }
  }, [isFav]);

  const toggleIsFav = async () => {
    if (!signedIn) {
      return navigate("/login");
    }
    if (isFav) {
      await supabase.from("favorites").delete().eq("id", isFav);
      setIsFav(null);
    } else {
      if (sound?.id) {
        const { data, error } = await supabase
          .from("favorites")
          .insert({
            sound_id: sound.id,
          })
          .select();
        if (data && !error) {
          setIsFav(data[0].id);
        }
      }
    }
  };

  return (
    sound && (
      <div className="flex flex-col flex-nowrap w-full sm:px-4 pt-6">
        <Helmet>
          <title>4J.ee | {sound.text} Sound Button</title>
          <meta
            name="description"
            content={`${sound.text} meme sound button, download ${sound.text} mp3 or ${sound.text} free ringtone for ios and android`}
          ></meta>
          <meta
            name="keywords"
            content={`${sound.text} sound button, ${sound.text} meme sound, ${sound.text} sound effect`}
          />
          <link rel="canonical" href={`https://4j.ee/sound/${sound.slug}`} />

          <meta
            property="og:title"
            content={`4J.ee | ${sound.text} Sound Button`}
          />
          <meta
            property="og:url"
            content={`https://4j.ee${location.pathname}`}
          />

          <meta
            property="og:image"
            content={renderLocationImage(location.pathname)}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={`${sound.text} meme sound button, download ${sound.text} mp3 or ${sound.text} free ringtone for ios and android`}
          />
        </Helmet>
        <div className="w-full mb-4 flex flex-row justify-center">
          <Breadcrumbs
            items={[
              {
                link: "/",
                title: "Home",
                key: "1",
              },
              {
                link: `/category/${sound.cat_slug}`,
                title: sound.cat_name ?? "",
                key: "2",
              },
              {
                link: `/sound/${slug}`,
                title: sound.text ?? "",
                key: "3",
              },
            ]}
          />
        </div>
        <div className="w-full text-center mt-4">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {sound.text} Sound Button
          </h1>
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-center">
          <div className="w-full sm:w-1/2 mt-4 flex justify-center">
            <Button
              {...sound}
              buttonClassName="w-42 sm:w-52 h-auto"
              type="svg"
              className="mx-2 sm:mx-3 mb-6 sm:mb-8 h-44 sm:h-52"
            />
          </div>
          <div className="w-full sm:w-1/2 mt-4">
            <div className="w-full flex flex-col sm:flex-row flex-wrap self-start">
              <div className="w-full md:w-1/2 h-12">
                <button
                  className="w-full h-12 bg-blue-600 text-white font-semibold rounded-md"
                  onClick={() => {
                    saveAs(`${cdnUrl()}/${sound.soundUrl}`, `${slug}.mp3`);
                  }}
                >
                  Download ringtone
                </button>
              </div>
              <div className="w-full md:w-1/2 h-12">
                <button
                  className="mt-2 md:mt-0 ml-0 md:ml-4 w-full h-12 bg-blue-600 text-white font-semibold rounded-md"
                  onClick={() => {
                    saveAs(`${cdnUrl()}/${sound.soundUrl}`, `${slug}.mp3`);
                  }}
                >
                  Download MP3
                </button>
              </div>
              <div className="w-full md:w-1/2 h-12">
                <button
                  className="mt-4 md:mt-2 ml-0 w-full h-12 bg-blue-600 text-white font-semibold rounded-md"
                  onClick={() => {
                    saveAs(`${cdnUrl()}/${sound.soundUrl}`, `${slug}.mp3`);
                  }}
                >
                  Download for Discord
                </button>
              </div>
              <div className="w-full md:w-1/2 h-12">
                <button
                  className="mt-6 md:mt-2 ml-0 md:ml-4 w-full h-12 bg-red-600 text-white font-semibold rounded-md"
                  onClick={async () => {
                    await toggleIsFav();
                  }}
                >
                  {isFav ? "Remove from favorites" : "Add to favorites"}
                </button>
              </div>
            </div>
            <div className="mt-8 md:mt-6 flex flex-row flex-wrap items-center justify-center md:justify-start">
              <FavoriteFillSVG className="w-6 h-6 text-red-600" />
              <span className="text-gray-800 ml-2">
                Favorited <b>{sound.favCount ?? 0}</b> times
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
