import { useQuery } from "urql";
import { graphql } from "../../gql";
import { Link } from "react-router-dom";

const PostsQuery = graphql(`
  query PostTitles {
    posts {
      data {
        attributes {
          slug
          title
        }
      }
    }
  }
`);

export const Blog = () => {
  const [result] = useQuery({
    query: PostsQuery,
  });

  const { data, fetching, error } = result;

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  return (
    <div className="flex flex-col flex-nowrap w-full sm:px-4 pt-6">
      <h1 className="text-2xl mb-2">Blog posts</h1>
      <ul className="list-disc">
        {data?.posts?.data.map(({ attributes }) => (
          <li key={attributes?.slug}>
            <Link to={`/blog/${attributes?.slug}`} className="underline">{attributes?.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
