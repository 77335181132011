import { createClient } from "@supabase/supabase-js";
import { Database } from "../../types/supabase";
import { SUPABASE_URL, SUPABASE_TOKEN } from "./const";

const options = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
};
export const supabase = createClient<Database, "public">(
  SUPABASE_URL,
  SUPABASE_TOKEN,
  options
);

export const getSoundBySlug = async (slug: string) =>
  supabase.from("sounds_view").select("*").eq("slug", slug).limit(1).single();
