import { useEffect, useState } from "react";
import { ButtonProps, fromDb } from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { supabase } from "../../lib/client";
import Helmet from "react-helmet";
import { Hero } from "./Hero";
import { ArrowTrendingUpIconSVG } from "../../components/Button/buttons";
import { ClockIconSVG } from "../../components/Button/buttons";
import { InputButton } from "../../components/InputButton";
import { Link } from "react-router-dom";
import { renderLocationImage } from "../../lib/util";

export const Home = () => {
  const [trendingSounds, setTrendingSounds] = useState<ButtonProps[]>([]);
  const [newSounds, setNewSounds] = useState<ButtonProps[]>([]);
  useEffect(() => {
    fetchTrending();
    fetchNew()
  }, []);

  const _query = () => supabase.from("sounds_view").select("*").range(0, 30);
  const fetchTrending = async () => {
    const { data, error } = await _query().order("listen_count", {
      ascending: false,
    });
    if (!error) {
      const buttonProps = data.map((d) => fromDb(d));
      setTrendingSounds(buttonProps);
    }
  };
  const fetchNew = async () => {
    const { data, error } = await _query().order("created_at", {
      ascending: false,
    });
    if (!error) {
      const buttonProps = data.map((d) => fromDb(d));
      setNewSounds(buttonProps);
    }
  };
  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>4J.ee | Sound Buttons</title>
        <meta
          name="description"
          content="Check out the coolest sound buttons made from memes, jokes, and viral sounds on Instagram Reels, TikTok, and YouTube!"
        ></meta>
        <meta
          name="keywords"
          content="soundboard sounds, sound buttons, sound button, soundboard, sound board, meme sound buttons, meme sounds, sound effects, meme sound effects, funny soundboard, soundboard meme"
        />
        <link rel="canonical" href={`https://4j.ee`} />
        <meta property="og:title" content="4J.ee | Sound Buttons" />
        <meta property="og:url" content="https://4j.ee" />

        <meta property="og:image" content={renderLocationImage()} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Check out the coolest sound buttons made from memes, jokes, and viral sounds on Instagram Reels, TikTok, and YouTube!" />
      </Helmet>
      <Hero />
      <div className="flex flex-col flex-nowrap items-center">
        <ButtonGroup
          categoryTitle="Trending Sounds"
          buttons={trendingSounds}
          CategoryIcon={
            <ArrowTrendingUpIconSVG className="w-6 h-6 text-blue-600 " />
          }
        />
        <div className="w-80">
          <Link to="/trending">
            <InputButton className="w-full flex flex-row flex-wrap justify-center items-center">
              <ArrowTrendingUpIconSVG className="w-4 h-4 text-white mr-2" />
              <span>All Trending SoundButtons</span>
            </InputButton>
          </Link>
        </div>

        <ButtonGroup
          categoryTitle="New Sounds"
          buttons={newSounds}
          CategoryIcon={
            <ClockIconSVG className="w-6 h-6 text-blue-600 " />
          }
        />
        <div className="w-80">
          <Link to="/new">
            <InputButton className="w-full flex flex-row flex-wrap justify-center items-center">
              <ClockIconSVG className="w-4 h-4 text-white mr-2" />
              <span>All New SoundButtons</span>
            </InputButton>
          </Link>
        </div>
      </div>
    </>
  );
};
