import { useCallback, useEffect, useRef, useState } from "react";
import { useWavesurfer } from "../../lib/wavesurfer";
import { WaveSurferOptions } from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import type { Region } from "wavesurfer.js/dist/plugins/regions";
import { TwoThumbsDraggableTrack } from "../Range";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";

interface IWaveSurferProps extends Partial<WaveSurferOptions> {
  height: number;
  blob?: Blob;
  onReady: (data: Blob) => Promise<void>
}
export const WaveSurfer = (props: IWaveSurferProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const activeRegion = useRef<Region | undefined>();
  const definerRegion = useRef<Region | undefined>();
  const { wavesurfer, download } = useWavesurfer(containerRef, props);
  const [link, setLink] = useState<string | null>(null);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [dimensions, setDimensions] = useState([0, 0]);

  useEffect(() => {
    if (!wavesurfer) return;

    if (props.blob) {
      wavesurfer.loadBlob(props.blob);
    }
    setCurrentTime(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("timeupdate", (currentTime) => {
        setCurrentTime(currentTime);
      }),
      wavesurfer.on("decode", () => {
        const duration = wavesurfer.getDuration();
        const wsRegions: RegionsPlugin =
          wavesurfer.getActivePlugins()[1] as RegionsPlugin;
        const reg = wsRegions.addRegion({
          start: dimensions[0],
          end: dimensions[1] > 0 ? dimensions[1] : duration,
          content: "Trim sound",
          color: "rgba(74,222,128, 0.5)",
          minLength: Math.min(duration, 2),
          maxLength: 180,
        });
        reg.on(
          "update",
          () => {
            setDimensions([reg.start, reg.end]);
          },
          { once: false }
        );

        definerRegion.current = reg;
        activeRegion.current = reg;

        // wsRegions.on("region-clicked", (region, e) => {
        //   e.stopPropagation(); // prevent triggering a click on the waveform

        //   activeRegion.current = region;
        //   region.setOptions({

        //   })
        //   region.play();
        // });
        wsRegions.on("region-out", (region) => {
          if (activeRegion?.current === region) {
            region.play();
          }
        });
        wsRegions.on("region-in", (region) => {
          activeRegion.current = region;
        });
        wavesurfer.on("click", (e) => {
          wavesurfer.seekTo(e);
          wavesurfer.play();
        });
        wavesurfer.on("interaction", () => {
          activeRegion.current = undefined;
        });

        setDuration(wavesurfer.getDuration());
        setDimensions([0, wavesurfer.getDuration()]);
      }),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  const tryDownload = async () => {
    if (activeRegion.current) {
      const blob = await download({
        start: dimensions[0],
        end: dimensions[1],
        kbps: 256,
      });
      props.onReady(blob);
      if (blob) {
        setLink(window.URL.createObjectURL(blob));
      }
    }
  };

  const onPlayClick = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
    }
  }, [wavesurfer]);
  return (
    <div className="flex flex-col flex-nowrap">
      <div className="mt-4 mb-2 mx-6">
        {duration && (
          <TwoThumbsDraggableTrack
            min={0}
            max={duration}
            v0={dimensions[0]}
            v1={dimensions[1]}
            setRange={(vals) => {
              if (definerRegion.current) {
                definerRegion.current.setOptions({
                  start: vals[0],
                  end: vals[1],
                });
                // if (vals[1] - vals[0] < 2) {
                //   vals[1] = 2 - vals[0]
                // }
                setDimensions(vals)
              }
            }}
          />
        )}
      </div>

      <div ref={containerRef} className="h-32 mx-2"></div>
      <div className="w-full flex flex-row flex-wrap">
        <div className="w-full sm:w-1/2">
          <button
            onClick={onPlayClick}
            className="my-2 p-2 border rounded-md bg-blue-500 text-white font-semibold w-full"
          >
            {isPlaying ? (
              <div className="flex flex-row flex-nowrap items-center justify-center">
                <PauseIcon className="w-4 h-4 mr-1" />
                <p>Pause</p>
              </div>
            ) : (
              <div className="flex flex-row flex-nowrap items-center justify-center">
                <PlayIcon className="w-4 h-4 mr-1" />
                <p>Play</p>
              </div>
            )}
          </button>
        </div>
        {/* <div className="w-full sm:w-1/3 flex flex-row flex-nowrap"> */}
          {/* <input
            type="text"
            className="block w-1/2 p-2 ml-0 sm:ml-2 my-2 rounded-md bg-clip-padding border border-solid border-neutral-200 bg-neutral-300"
            value={currentTime}
            disabled={true}
          />
          <p className="p-2 my-2 text-gray-400">/</p>
          <input
            type="text"
            className="block w-1/2 p-2 my-2 rounded-md bg-neutral-300 bg-clip-padding border border-solid border-neutral-200"
            disabled={true}
            value={duration}
          /> */}
        {/* </div> */}
        <div className="w-full sm:w-1/2 flex flex-row flex-nowrap">
          <button className="my-2 p-2 border rounded-md bg-blue-500 text-white font-semibold w-full" onClick={() => tryDownload()}>Finish!</button>
        </div>
        
        {/* <a href={link ?? ""} download={"foo.mp3"}>
          download
        </a> */}
      </div>
    </div>
  );
};
