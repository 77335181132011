import classNames from "classnames";
import { Item } from "./Item";

import { CategoryRow } from "../../routes/Root";
import { CategoryIconType, CategoryPicker } from "../Button/buttons/Picker";

interface IDesktopCategoriesProps {
  showCategories: boolean;
  setShowCategories: (show: boolean) => void;
  categories: CategoryRow[] | null | undefined;
}

export const Desktop = ({
  showCategories,
  setShowCategories,
  categories,
}: IDesktopCategoriesProps) => {
  const classes = classNames(
    "bg-black",
    "flex",
    "flex-row",
    "flex-wrap",
    "px-8",
    "justify-evenly",
    {
      hidden: !showCategories,
    }
  );
  return (
    <div className={classes}>
      {categories &&
        categories.map((c) => (
          <Item
            icon={<CategoryPicker name={c.icon as CategoryIconType} />}
            title={c.name}
            setShowCategories={setShowCategories}
            to={`/category/${c.slug}`}
            key={`cat-d-${c.slug}`}
          />
        ))}
    </div>
  );
};
