import { S3_HOST } from "../lib/const";

export const base64ToBufferAsync = async (base64: string) => {
  var dataUrl = "data:application/octet-binary;base64," + base64;

  const res = await fetch(dataUrl);
  const buffer = await res.arrayBuffer();
  return new Uint8Array(buffer);
};


export const renderLocationImage = (location?: string) => `https://i.4j.ee/take?url=https://4j.ee${location ?? ''}&format=jpeg&quality=80&wait-until-event=networkidle2&viewport-width=1200&viewport-height=630&ttl=86400`

export const cdnUrl = (iso_code?: string) => {
  switch (iso_code) {
    case ["US", "CA"].find((c) => c === iso_code):
      return S3_HOST;
    default:
      return "https://api.4j.ee/storage/v1/object/public/media-4j-ee";
  }
};