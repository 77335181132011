import { NavLink, useNavigate } from "react-router-dom";

export type ItemProps = {
  icon: React.ReactElement;
  title: string;
  setShowCategories: (show: boolean) => void
  to?: string
};

export const Item = ({ icon, title, setShowCategories, to }: ItemProps) => {
  const navigate = useNavigate()
  return (
    <NavLink to={to ?? "/"} role="catitem" onClick={()=> {
      setShowCategories(false)
      navigate(to ?? "/")
    }
      }>
      <div className="p-2 my-2 sm:my-0 text-gray-300 flex flex-row items-center ">
        <span className="mr-1 w-4 h-4">{icon}</span>
        <span>{title}</span>
      </div>
    </NavLink>
  );
};
