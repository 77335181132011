import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { ButtonProps } from "../../components/Button";
import { supabase } from "../../lib/client";
import { fromDb } from "../../components/Button/index";
import Helmet from "react-helmet";
import { CategoryRow } from "../Root";
import { ButtonGroup } from "../../components/ButtonGroup";
import { ChevronDownSVG } from "../../components/Button/buttons";
import { InputButton } from "../../components/InputButton";
import {
  CategoryIconType,
  CategoryPicker,
} from "../../components/Button/buttons/Picker";
import { renderLocationImage } from "../../lib/util";

export const Category = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [categorySounds, setCategorySounds] = useState<ButtonProps[]>([]);
  const [category, setCategory] = useState<CategoryRow | null>();

  const fetchTotal = async (categoryId: number) => {
    const { data, error } = await supabase
      .from("category_sound_count")
      .select("*")
      .eq("category_id", categoryId)
      .single();
    if (!error && data?.count) {
      setTotal(data.count);
    }
  };

  const fetchCategorySounds = async (categoryId: number) => {
    await fetchTotal(categoryId);
    const { data, error } = await supabase
      .from("sounds_view")
      .select("*")
      .eq("cat_id", categoryId)
      .range(0, 53)
      .order("listen_count", { ascending: false });
    if (data && !error) {
      const buttonProps = data.map((d) => fromDb(d));
      setCurrentOffset(data.length);
      setCategorySounds(buttonProps);
    }
  };

  const fetchMore = async (categoryId: number) => {
    const { data, error } = await supabase
      .from("sounds_view")
      .select("*")
      .eq("cat_id", categoryId)
      .range(currentOffset, currentOffset + 53)
      .order("listen_count", { ascending: false });
    if (!error) {
      const buttonProps = data.map((d) => fromDb(d));
      setCurrentOffset((prev) => prev + data.length);
      setCategorySounds((prev) => [...prev, ...buttonProps]);
    }
  };

  const fetchCategoryInfo = async () => {
    if (slug) {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .eq("slug", slug)
        .single();
      if (data && !error) {
        setCategory(data);
        await fetchCategorySounds(data.id);
      }
      if (error) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (slug) {
      fetchCategoryInfo();
    }
  }, [slug]);

  return (
    category && (
      <div className="flex flex-col flex-nowrap items-center">
        <Helmet>
          <title>4J.ee | {category.name} Sound Buttons</title>
          <meta name="description" content={category.description ?? ""}></meta>
          <meta name="keywords" content={category.keywords ?? ""} />
          <link
            rel="canonical"
            href={`https://4j.ee/category/${category.slug}`}
          />
          <meta
            property="og:title"
            content={`4J.ee | ${category.name} Sound Buttons`}
          />
          <meta
            property="og:url"
            content={`https://4j.ee${location.pathname}`}
          />

          <meta
            property="og:image"
            content={renderLocationImage(location.pathname)}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={category.description ?? ''}
          />
        </Helmet>
        <ButtonGroup
          categoryTitle={category.name}
          buttons={categorySounds}
          CategoryIcon={
            <CategoryPicker
              name={category.slug as CategoryIconType}
              className="w-6 h-6 text-blue-600 "
            />
          }
        />
        <div className="w-80">
          <InputButton
            className="w-full flex flex-row flex-wrap justify-center items-center"
            disabled={currentOffset >= total}
            onClick={() => fetchMore(category.id)}
          >
            {" "}
            {currentOffset >= total ? (
              <span>That's all</span>
            ) : (
              <>
                <ChevronDownSVG className="w-4 h-4 text-white mr-2" />
                <span>Load more</span>
              </>
            )}
          </InputButton>
        </div>
      </div>
    )
  );
};
