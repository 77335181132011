import { Picker, PickerColors, PickerVariants } from "./buttons/Picker";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { DownloadSVG, FavoriteSVG, FavoriteFillSVG, ShareSVG } from "./buttons";
import type { Get } from "type-fest";
import { DbType } from "../../lib/const";
import { usePlayerContext } from "../../contexts/player";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/client";
import { useUserContext } from "../../contexts/user";
import { saveAs } from "file-saver";
import { cdnUrl } from "../../lib/util";

export type ViewRow = Get<DbType, "public.Views.sounds_view.Row">;

export const fromDb = (
  {
    color,
    id,
    sound_path,
    title,
    slug,
    f_id,
    cat_name,
    cat_slug,
    fc_sum,
  }: ViewRow,
  className?: string
): ButtonProps => {
  return {
    id: id ?? undefined,
    className,
    cat_name,
    cat_slug,
    slug: slug ?? "",
    color: (color as PickerColors) ?? "brown",
    text: title ?? "",
    favId: f_id,
    favCount: fc_sum,
    // soundUrl: `${path_tokens?.join("/")}${S3_SKIP_VERSION ? '' : `/${version}`}`
    soundUrl: sound_path ?? "",
  };
};

export const DEFAULT_CLASS = "mx-2 sm:mx-3 mb-6 sm:mb-8";
export type ButtonProps = {
  id?: number;
  color: PickerColors;
  text?: string;
  className?: string;
  buttonClassName?: string;
  soundUrl: string;
  slug: string;
  type?: "svg" | "png";
  favId?: number | null;
  favCount?: number | null;
  cat_name?: string | null;
  cat_slug?: string | null;
};
export const Button = ({
  id,
  color,
  text = "gulp gulp gulp gulp",
  soundUrl,
  slug,
  favId,
  ...rest
}: ButtonProps) => {
  const { play } = usePlayerContext();
  const { signedIn } = useUserContext();
  const navigate = useNavigate();
  const [variant, setVariant] = useState<PickerVariants>("pressed");
  const [isFav, setIsFav] = useState(favId);
  const forwardToPlayerContext = async () => await play(soundUrl, id);
  const rootClass = classNames(
    "w-24",
    "h-36",
    // "sm:h-40",
    "flex",
    "flex-col",
    "flex-nowrap",
    "justify-between",
    rest?.className ?? DEFAULT_CLASS
  );

  useEffect(() => {
    setIsFav(favId);
  }, [favId]);
  const toggleIsFav = async () => {
    if (!signedIn) {
      return navigate("/login");
    }
    if (isFav) {
      await supabase.from("favorites").delete().eq("id", isFav);
      setIsFav(null);
    } else {
      if (id) {
        const { data, error } = await supabase
          .from("favorites")
          .insert({
            sound_id: id,
          })
          .select();
        if (data && !error) {
          setIsFav(data[0].id);
        }
      }
    }
  };
  const copyToClipboard = async (url: string) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();

      await navigator.clipboard.write([
        new ClipboardItem({
          [`web ${blob.type}`]: blob,
        }),
      ]);
      console.log("Fetched image copied.");
    } catch (err) {
      const e = err as { name: string; message: string };
      console.error(e.name, e.message);
    }
  };
  return (
    <div className={rootClass}>
      <div>
        {/* <div className="flex-row flex-wrap mb-1 justify-center hidden sm:flex">
          <span className="text-gray-400 text-[8px] font-normal">95.4K</span>
          <PlayIcon className="w-2 h-3 text-gray-400" />
        </div> */}
        <div className="flex flex-col flex-nowrap items-center">
          <button
            className="inline-flex outline-none focus:outline-none"
            type="button"
            onClick={async () => await forwardToPlayerContext()}
            onPointerEnter={() => setVariant("original")}
            onPointerLeave={() => setVariant("pressed")}
          >
            <Picker
              type={rest.type ?? "png"}
              variant={variant}
              color={color}
              className={rest.buttonClassName ?? "w-24 h-auto"}
              alt={`${text} Sound Button`}
            />
          </button>
          <Link
            to={`/sound/${slug}`}
            className="text-sm pt-3 text-center underline"
          >
            {text}
          </Link>
        </div>
      </div>
      <div className="flex flex-row flex-nowrap justify-center">
        <button
          type="button"
          className="mr-3"
          onClick={async () => await toggleIsFav()}
        >
          {isFav ? (
            <FavoriteFillSVG className="w-6 sm:w-5 h-auto text-red-500" />
          ) : (
            <FavoriteSVG className="w-6 sm:w-5 h-auto text-red-500" />
          )}
        </button>
        <button
          type="button"
          className="mr-3"
          onClick={() => {
            saveAs(`${cdnUrl()}/${soundUrl}`, `${slug}.mp3`);
          }}
        >
          <DownloadSVG className="w-6 sm:w-5 h-auto text-blue-500" />
        </button>
        <button
          type="button"
          onClick={async () => {
            await copyToClipboard(`${cdnUrl()}/${soundUrl}`);
          }}
        >
          <ShareSVG className="w-6 sm:w-5 h-auto text-green-500" />
        </button>
      </div>
    </div>
  );
};
