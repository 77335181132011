import classNames from "classnames";

interface InputButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}
export const InputButton = ({ children, ...props }: InputButtonProps) => {
  const additionalClasses = (props.className ?? "").split(" ");
  const classes = classNames(
    "rounded-md",
    "bg-blue-600",
    "disabled:bg-blue-200",
    "px-3",
    "py-1.5",
    "text-sm",
    "font-semibold",
    "leading-6",
    "text-white",
    "shadow-sm",
    "hover:bg-blue-500",
    "focus-visible:outline",
    "focus-visible:outline-2",
    "focus-visible:outline-offset-2",
    "focus-visible:outline-blue-600",
    ...additionalClasses
  );
  return (
    <button {...props} className={classes}>{children}</button>
  )
};
