import { useQuery } from "urql";
import { graphql } from "../../gql";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Helmet from "react-helmet";
import MarkdownIt from "markdown-it";
import { renderLocationImage } from "../../lib/util";

const PostQuery = graphql(`
  query Post($slug: String) {
    posts(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          title
          slug
          content
          seo{
            metaTitle
            metaDescription
            keywords
            canonicalURL
          }
        }
      }
    }
  }
`);

const md = new MarkdownIt({
  html: false, // Enable HTML tags in source
  xhtmlOut: true, // Use '/' to close single tags (<br />).
  breaks: true, // Convert '\n' in paragraphs into <br>                            // useful for external highlighters.
  linkify: true, // Autoconvert URL-like text to links
  quotes: "“”‘’",
});

export const BlogItem = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!slug) {
      navigate("/blog");
    }
  }, [slug]);

  const [{ data, error }] = useQuery({
    query: PostQuery,
    variables: {
      slug,
    },
  });

  const item = data?.posts?.data[0];
  return item ? (
    <div className="flex flex-col flex-nowrap w-full sm:px-4 pt-6">
      <Helmet>
          <title>{item.attributes?.seo?.metaTitle}</title>
          <meta
            name="description"
            content={item.attributes?.seo?.metaDescription}
          ></meta>
          <meta
            name="keywords"
            content={item.attributes?.seo?.keywords ?? ""}
          />
          <link rel="canonical" href={`https://4j.ee/blog/${slug}`} />

          <meta
            property="og:image"
            content={renderLocationImage(location.pathname)}
          />
        </Helmet>
      <div className="w-full mb-4 flex flex-row justify-center">
        <Breadcrumbs
          items={[
            {
              link: "/",
              title: "Home",
              key: "1",
            },
            {
              link: `/blog`,
              title: "Blog",
              key: "2",
            },
            {
              link: `/blog/${data?.posts?.data[0].attributes?.slug}`,
              title: data?.posts?.data[0].attributes?.title ?? "",
              key: "3",
            },
          ]}
        />
      </div>
      <h1 className="text-3xl w-full text-center">
        {data?.posts?.data[0].attributes?.title}
      </h1>
      <div className="text-left mt-2" dangerouslySetInnerHTML={{__html: md.render(data?.posts?.data[0].attributes?.content ?? "")}}></div>
    </div>
  ) : (
    <>Not Found</>
  );
};
