import { createBrowserRouter } from "react-router-dom";
import { Root } from "./Root";
import { Login } from "./Login";
import { New } from "./New";
import { Home } from "./Home";
import { Upload } from "./Upload";
import { Sound } from "./Sound";
import { Trending } from "./Trending";
import { Category } from "./Category";
import { Blog } from "./Blog";
import { BlogItem } from "./Blog/item";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        index: true,
        element: <Home />
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "upload",
        element: <Upload />,
      },
      {
        path: "sound/:slug",
        element: <Sound />
      },
      {
        path: "category/:slug",
        element: <Category />
      },
      {
        path: "new",
        element: <New />
      },
      {
        path: "trending",
        element: <Trending />
      },
      {
        path: "blog",
        element: <Blog/>
      },
      {
        path: "blog/:slug",
        element: <BlogItem/>
      }
    ],
  },
]);