import { NavLink } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

interface IDesktopMenuProps {
  showCategories: boolean;
  setShowCategories: (flag: boolean) => void;
}
export const DesktopMenu = ({
  showCategories,
  setShowCategories,
}: IDesktopMenuProps) => {
  const classes = (isActive: boolean) =>
    classNames("rounded-md", "px-3", "py-2", "text-sm", "font-medium", {
      "bg-gray-900": isActive,
      "text-white": isActive,
      "text-gray-300": !isActive,
    });
  return (
    <div className="flex space-x-4">
      <NavLink
        to="/"
        aria-current="page"
        className={({ isActive, isPending }) => classes(isActive)}
      >
        HOME
      </NavLink>

      <NavLink
        to="/new"
        className={({ isActive, isPending }) => classes(isActive)}
      >
        NEW
      </NavLink>
      <NavLink
        to="/trending"
        className={({ isActive, isPending }) => classes(isActive)}
      >
        TRENDING
      </NavLink>
      <button
        type="button"
        className="inline-flex items-center text-gray-300 rounded-md px-3 py-2 text-sm font-medium"
        aria-expanded="false"
        onClick={() => setShowCategories(!showCategories)}
        onBlur={(evt) => {
          const role = evt.relatedTarget?.getAttribute("role");
          if (!role) {
            setShowCategories(false);  
          }

        }}
      >
        <span>CATEGORIES</span>
        {showCategories ? (
          <ChevronUpIcon className="h-4 w-auto text-gray-300" />
        ) : (
          <ChevronDownIcon className="h-4 w-auto text-gray-300" />
        )}
      </button>
      <NavLink
        to="/upload"
        className="rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        UPLOAD
      </NavLink>
    </div>
  );
};
