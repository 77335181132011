import { useState } from "react";
import classNames from "classnames";
import { useUserContext } from "../../contexts/user";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate, Link } from "react-router-dom";

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signedIn, logout } = useUserContext();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const dropClassNames = classNames(
    "absolute",
    "right-0",
    "z-10",
    "mt-2",
    "w-48",
    "origin-top-right",
    "rounded-md",
    "bg-white",
    "py-1",
    "shadow-lg",
    "ring-1",
    "ring-black",
    "ring-opacity-5",
    "focus:outline-none",
    { hidden: !dropDownVisible }
  );

  const handleLogout = async (evt: React.MouseEvent) => {
    await logout();
    setDropDownVisible(false);
    if (location.pathname !== "/") {
      navigate("/");
    }
  };
  return (
    <div className="relative ml-3">
      <div className="text-right">
        {signedIn ? (
          <button
            type="button"
            className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            aria-expanded="false"
            aria-haspopup="true"
            onClick={() => setDropDownVisible(!dropDownVisible)}
            onBlur={ (evt) => {
              const role = evt.relatedTarget?.getAttribute("role");
              if (!role)
                setDropDownVisible(false)
              }
              
            }
          >
            <span className="absolute -inset-1.5"></span>
            <span className="sr-only">Open user menu</span>

            <UserCircleIcon className="h-8 w-8 text-blue-500" />
          </button>
        ) : (
          <Link
            to="/login"
            className="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium"
            aria-current="page"
          >
            Sign In
          </Link>
        )}
      </div>

      {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
      <div
        className={dropClassNames}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu-button"
        tabIndex={1}
      >
        {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
        <a
          href="/"
          className="block px-4 py-2 text-sm text-gray-700 text-left"
          role="menuitem"
          tabIndex={1}
          id="user-menu-item-0"
        >
          Your Profile
        </a>
        <a
          href="/"
          className="block px-4 py-2 text-sm text-gray-700 text-left"
          role="menuitem"
          tabIndex={2}
          id="user-menu-item-1"
        >
          Settings
        </a>
        <button
          onClick={async (evt) => await handleLogout(evt)}
          className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
          role="menuitem"
          tabIndex={3}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
