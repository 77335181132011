import { Button, ButtonProps } from "../Button";

export type ButtonGroupProps = {
  buttons: ButtonProps[];
  categoryTitle: string;
  CategoryIcon?: React.ReactElement;
};

export const ButtonGroup = ({
  buttons,
  categoryTitle,
  CategoryIcon,
}: ButtonGroupProps) => {
  return (
    <>
      <div className="flex flex-row flex-wrap justify-center sm:justify-start w-full sm:px-4 pt-6 ml-0 pl-0 mt-0 sm:mt-4">
        {CategoryIcon && CategoryIcon}
        <h2 className="mt-0 text-xl font-semibold pl-2 text-gray-800">
          {categoryTitle}
        </h2>
      </div>

      <div className="flex flex-row flex-wrap w-full justify-start sm:px-4 pt-6">
        {buttons.map((b, i) => (
          <Button {...b} key={i} />
        ))}
      </div>
    </>
  );
};
