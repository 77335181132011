import * as SVGs from "./index";

export type PickerColors =
  | "blue"
  | "brown"
  | "green"
  | "grey"
  | "light-brown"
  | "orange"
  | "pink"
  | "red"
  | "teal"
  | "violet"
  | "white"
  | "yellow";
export type PickerVariants = "original" | "pressed";
export type PickerProps = {
  variant: PickerVariants;
  color: PickerColors;
  type?: "png" | "svg";
  className?: string;
  alt?: string;
};
export const Picker: React.FC<PickerProps> = (props) => {
  const { variant, color, type = "png", alt } = props;
  if (type === "png") {
    return (
      <img src={`/buttons/${variant}/${color}.png`} {...props} alt={alt} />
    );
  }

  switch (variant) {
    case "original":
      switch (color) {
        case "blue":
          return <SVGs.BlueSVG {...props} />;
        case "brown":
          return <SVGs.BrownSVG {...props} />;
        case "green":
          return <SVGs.GreenSVG {...props} />;
        case "grey":
          return <SVGs.GreySVG {...props} />;
        case "light-brown":
          return <SVGs.LightBrownSVG {...props} />;
        case "orange":
          return <SVGs.OrangeSVG {...props} />;
        case "pink":
          return <SVGs.PinkSVG {...props} />;
        case "red":
          return <SVGs.RedSVG {...props} />;
        case "teal":
          return <SVGs.TealSVG {...props} />;
        case "violet":
          return <SVGs.VioletSVG {...props} />;
        case "white":
          return <SVGs.WhiteSVG {...props} />;
        case "yellow":
          return <SVGs.YellowSVG {...props} />;
      }
      break;
    case "pressed":
      switch (color) {
        case "blue":
          return <SVGs.BluePressedSVG {...props} />;
        case "brown":
          return <SVGs.BrownPressedSVG {...props} />;
        case "green":
          return <SVGs.GreenPressedSVG {...props} />;
        case "grey":
          return <SVGs.GreyPressedSVG {...props} />;
        case "light-brown":
          return <SVGs.LightBrownPressedSVG {...props} />;
        case "orange":
          return <SVGs.OrangePressedSVG {...props} />;
        case "pink":
          return <SVGs.PinkPressedSVG {...props} />;
        case "red":
          return <SVGs.RedPressedSVG {...props} />;
        case "teal":
          return <SVGs.TealPressedSVG {...props} />;
        case "violet":
          return <SVGs.VioletPressedSVG {...props} />;
        case "white":
          return <SVGs.WhitePressedSVG {...props} />;
        case "yellow":
          return <SVGs.YellowPressedSVG {...props} />;
      }
  }
};

export type CategoryIconType =
  | "musical-note"
  | "speaker-wave"
  | "fire"
  | "film"
  | "globe-europe-africa"
  | "radio"
  | "swatch";
export type CategoryIconProps = {
  name: CategoryIconType;
  className?: string;
};
export const CategoryPicker: React.FC<CategoryIconProps> = ({
  name,
  ...rest
}) => {
  switch (name) {
    case "musical-note":
      return <SVGs.MusicalNoteIconSVG {...rest} />;
    case "speaker-wave":
      return <SVGs.SpeakerWaveIconSVG {...rest} />;
    case "fire":
      return <SVGs.FireIconSVG {...rest} />;
    case "film":
      return <SVGs.FilmIconSVG {...rest} />;
    case "globe-europe-africa":
      return <SVGs.GlobeEuropeAfricaIconSVG {...rest} />
    case "radio":
      return <SVGs.RadioIconSVG {...rest} />
    case "swatch":
      return <SVGs.SwatchIconSVG {...rest} />
  }
};
