import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RealtimeChannel } from "@supabase/supabase-js";
import { supabase } from "../lib/client";

export interface IPayload {
  type: string;
  event: string;
  [key: string]: any;
}
export interface IRealtimeContext {
  subscription: RealtimeChannel | undefined;
  setChannelId: (channelId: string) => void;
  channelId: string;
  onMessage: ( fn: (payload: IPayload) => void) => void
}

const Context = createContext<IRealtimeContext>({} as IRealtimeContext);

export const RealtimeContext = ({ children }: { children: ReactNode }) => {
  const [sub, setSub] = useState<RealtimeChannel | undefined>(undefined);
  const [channelId, setChannelId] = useState<string>("");
  const listener = useRef<(payload: IPayload) => void>()

  const onMessage = (fn: (payload: IPayload) => void) => {
    listener.current = fn;
  }

  const fetchSubscription = (
    subscription: RealtimeChannel | undefined,
    chId: string
  ) => {
    console.log(chId)
    return subscription = supabase
      .channel(chId, {
        config: {
          broadcast: {
            self: true,
            ack: true
          }
        }
      })
      .on("broadcast", { event: "rt-msg" }, (payload) => {
        if (listener.current) {
          listener.current(payload)
        }
      })
      .subscribe((status) => {
        if (status === "SUBSCRIBED") {
          setSub(subscription)
        }
      });
  };

  useEffect(() => {
    let subscription: RealtimeChannel | undefined = undefined;
    if (channelId) {
      subscription = fetchSubscription(subscription, channelId);
    }
    
    return () => {
      subscription?.unsubscribe();
    };
  }, [channelId]);

  return (
    <Context.Provider value={{ subscription: sub, setChannelId, onMessage, channelId }}>
      {children}
    </Context.Provider>
  );
};

export const useRealtimeContext = () => useContext(Context);
