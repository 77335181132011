import { useQuery } from "urql";
import { graphql } from "../../gql";
import { Link } from "react-router-dom";

const PostsQuery = graphql(`
  query PostTitles {
    posts {
      data {
        attributes {
          slug
          title
        }
      }
    }
  }
`);
export const Footer = () => {
  const [result] = useQuery({
    query: PostsQuery,
  });

  const { data, fetching, error } = result;

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  return (
    // 
    <footer className="bg-white  mt-4 sm:mt-6 -mb-56 border-y-2 border-gray-100">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-3 ml-0">
          <div>
            <h2 className="mb-4 text-sm font-semibold text-gray-800 uppercase dark:text-white">
              Blog Posts
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {data?.posts?.data.map(({ attributes }, i) => (
                <li className="mb-2" key={`bi-${i}`}>
                  <Link to={`/blog/${attributes?.slug}`}>
                    {attributes?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* 
          
          <div>
            <h2 className="mb-4 text-sm font-semibold text-gray-800 uppercase">
              Legal
            </h2>
            <ul className="text-gray-500 font-medium">
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Blog
            </h2>
            <ul className="text-gray-500 font-medium">
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  iOS
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  Android
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  Windows
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  MacOS
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="px-4 py-6 bg-gray-100  md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500  sm:text-center">
            © 2023 <a href="https://4j.ee/">4j.ee</a>. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
