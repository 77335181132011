import React from "react";
import { Client, Provider, cacheExchange, fetchExchange } from 'urql';
import { UserContext } from "./contexts/user";
import { router } from "./routes/";
import { RouterProvider } from "react-router-dom";
import { PlayerContext } from "./contexts/player";
import { RealtimeContext } from "./contexts/realtime";
import ReactGA from "react-ga4";



const client = new Client({
  url: `${process.env.REACT_APP_STRAPI_URL}/graphql`,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = process.env.REACT_APP_STRAPI_TOKEN
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE ?? "");
  return (
    <React.StrictMode>
      <UserContext>
        <PlayerContext>
          <RealtimeContext>
            <Provider value={client}>
              <RouterProvider router={router} />
            </Provider>
          </RealtimeContext>
        </PlayerContext>
      </UserContext>
    </React.StrictMode>
  );
}

export default App;
