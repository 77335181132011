import { ElementType } from "react";
import { ChevronRightSVG, HomeSVG } from "../Button/buttons";
import { Link } from "react-router-dom";

export type BreadcrumbItem = {
  icon?: ElementType;
  link: string;
  title: string;
  alt?: string;
  key: string;
};

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        {items.map(({ icon: Icon, link, title, alt, key }, index) => (
          <li key={key} className="!ml-2">
            <div className="flex items-center">
              { (index > 0 && index < items.length) && (
                <ChevronRightSVG className="text-gray-800 w-4 h-auto mr-2" />
              ) }
              <Link
                to={link}
                className={
                  index === items.length-1
                    ? "inline-flex items-center text-sm font-medium text-gray-400"
                    : "inline-flex items-center text-sm font-medium text-gray-800 hover:text-blue-600"
                }
              >
                {Icon && (
                  <Icon
                    className={
                      index === items.length-1
                        ? ""
                        : "text-gray-400 w-4 h-auto mr-2"
                    }
                  />
                )}
                {title}
              </Link>
            </div>
          </li>
        ))}

      </ol>
    </nav>
  );
};
