import { useEffect, useState } from "react";
import { Desktop as CategoriesDesktop } from "../components/Categories/Desktop";
import { Navbar } from "../components/Navbar";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { usePlayerContext } from "../contexts/player";
import { Footer } from "../components/Footer";
import type { Get } from "type-fest";
import { DbType } from "../lib/const";
import { supabase } from "../lib/client";

export type CategoryRow = Get<DbType, "public.Tables.categories.Row">;

export const Root = () => {
  const [categories, setCategories] = useState<Array<CategoryRow> | null>()
  const [showCategories, setShowCategories] = useState(false);
  const player = usePlayerContext()
  const location = useLocation();

  const fetchCategories = async () => {
    const { data} = await supabase.from("categories").select("*").order("position", {ascending: true})
    setCategories(data)
  }
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    if (player.sound) {
      player.stop();
    }
  }, [location]);

  useEffect( () => {
    fetchCategories();
  }, [location])
  return (
    <div>
      <Navbar
        showCategories={showCategories}
        setShowCategories={setShowCategories}
        categories={categories}
      />
      <CategoriesDesktop showCategories={showCategories} setShowCategories={setShowCategories} categories={categories} />
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 flex flex-col h-screen justify-between">
        <Outlet />
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  );
};
