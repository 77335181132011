import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ButtonProps, fromDb } from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { supabase } from "../../lib/client";
import Helmet from "react-helmet";
import { ChevronDownSVG, ClockIconSVG } from "../../components/Button/buttons";
import { InputButton } from "../../components/InputButton";
import { renderLocationImage } from "../../lib/util";

export const New = () => {
  const [newSounds, setNewSounds] = useState<ButtonProps[]>([]);
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  useEffect(() => {
    fetchTrending();
    fetchTotal();
  }, []);

  const fetchTotal = async () => {
    const { data, error } = await supabase
      .from("sounds_count")
      .select("*")
      .single();
    if (!error && data?.count) {
      setTotal(data.count);
    }
  };

  const fetchMore = async () => {
    const { data, error } = await supabase
      .from("sounds_view")
      .select("*")
      .range(currentOffset, currentOffset + 53)
      .order("created_at", { ascending: false });
    if (!error) {
      const buttonProps = data.map((d) => fromDb(d));
      setCurrentOffset((prev) => prev + data.length);
      setNewSounds((prev) => [...prev, ...buttonProps]);
    }
  };

  const fetchTrending = async () => {
    const { data, error } = await supabase
      .from("sounds_view")
      .select("*")
      .range(0, 53)
      .order("created_at", { ascending: false });
    if (!error) {
      const buttonProps = data.map((d) => fromDb(d));
      setCurrentOffset(data.length);
      setNewSounds(buttonProps);
    }
  };
  return (
    <div className="flex flex-col flex-nowrap items-center">
      <Helmet>
        <title>4J.ee | New Sound Buttons</title>
        <meta
          name="description"
          content="Newest sound buttons made from memes, jokes, and viral sounds on Instagram Reels, TikTok, and YouTube"
        ></meta>
        <meta
          name="keywords"
          content="soundbuttons, sound buttons, soundboards, meme sound buttons, meme sounds, sound effects, new memes, new sounds"
        />
        <link rel="canonical" href={`https://4j.ee/new`} />

        <meta
          property="og:title"
          content="4J.ee | New Sound Buttons"
        />
        <meta property="og:url" content={`https://4j.ee${location.pathname}`} />

        <meta
          property="og:image"
          content={renderLocationImage(location.pathname)}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Newest sound buttons made from memes, jokes, and viral sounds on Instagram Reels, TikTok, and YouTube"
        />
      </Helmet>
      <ButtonGroup
        categoryTitle="New Sounds"
        buttons={newSounds}
        CategoryIcon={<ClockIconSVG className="w-6 h-6 text-blue-600 " />}
      />
      <div className="w-80">
        <InputButton
          className="w-full flex flex-row flex-wrap justify-center items-center"
          disabled={currentOffset >= total}
          onClick={() => fetchMore()}
        >
          {" "}
          {currentOffset >= total ? (
            <span>That's all</span>
          ) : (
            <>
              <ChevronDownSVG className="w-4 h-4 text-white mr-2" />
              <span>Load more</span>
            </>
          )}
        </InputButton>
      </div>
    </div>
  );
};
