import classNames from "classnames";
import { Item } from "./Item";
import { CategoryRow } from "../../routes/Root";
import { CategoryIconType, CategoryPicker } from "../Button/buttons/Picker";

interface IMobileCategoriesProps {
  showCategories: boolean;
  setShowCategories: (show: boolean) => void
  categories: CategoryRow[] | null | undefined;
}

export const Mobile = ({ showCategories, setShowCategories, categories }: IMobileCategoriesProps) => {
  const classes = classNames(
    "bg-black",
    "flex",
    "flex-col",
    "flex-nowrap",
    "px-8",
    "items-center",
    {
      hidden: !showCategories,
    }
  );
  return (
    <div className={classes}>
      {categories &&
        categories.map((c) => (
          <Item
            icon={<CategoryPicker name={c.icon as CategoryIconType} />}
            title={c.name}
            setShowCategories={setShowCategories}
            to={`/category/${c.slug}`}
            key={`cat-d-${c.slug}`}
          />
        ))}
    </div>
  );
};
